const dev = {
  s3: {
    REGION: "us-east-1",
  },
  apiGateway: {
    URL: "http://localhost:8080/",
  },
};

const test = {
  s3: {
    REGION: "us-east-1",
  },
  apiGateway: {
    URL: "https://api.realleague.alexwiss.com/",
  },
};

const prod = {
  s3: {
    REGION: "us-east-1",
  },
  apiGateway: {
    URL: "https://api.realleague.alexwiss.com/",
  },
};

// Default to dev if not set
const config =
  process.env.REACT_APP_STAGE === "prod"
    ? prod
    : process.env.REACT_APP_STAGE === "test"
    ? test
    : dev;

const defaults = {
  // Add common config values here
  colorScheme: { scheme: "category10" },
  ...config,
};

export default defaults;
